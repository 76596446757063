export enum ABDState {
    AUTHENTICATING = "AUTHENTICATING",
    READY_FOR_NEXT_BAG_IN_GROUP = "READY_FOR_NEXT_BAG_IN_GROUP",
    SENDING_BAG = "SENDING_BAG",
    CHOOSING_BAG_TYPE = "CHOOSING_BAG_TYPE",
    BAG_READY_TO_BE_SCALED = "BAG_READY_TO_BE_SCALED",
    TAG_READY_TO_BE_SCANNED = "TAG_READY_TO_BE_SCANNED",
    MANUAL_MODE = "MANUAL_MODE",
    CHECKING_BAG_DATA = "CHECKING_BAG_DATA",
    BAG_SENT = "BAG_SENT",
    OUT_OF_ORDER = "OUT_OF_ORDER",
    REMOVING_BAG_BEFORE_TRAY_DELIVERY = "REMOVING_BAG_BEFORE_TRAY_DELIVERY",
    TRAY_REQUESTED = "TRAY_REQUESTED",
    DELIVERING_TRAY = "DELIVERING_TRAY",
    CHOOSE_AIRLINE = "CHOOSE_AIRLINE",
    BAG_SEND_FAILURE = "BAG_SEND_FAILURE",
    REVERSING_BAG = "REVERSING_BAG",
    BAG_WAS_REVERSED = "BAG_WAS_REVERSED",
    BAG_REJECTED_AFTER_SCALE = "BAG_REJECTED_AFTER_SCALE",
    BAG_SEND_FAILURE_AND_BUFFERED_BAGS = "BAG_SEND_FAILURE_AND_BUFFERED_BAGS",
    TRAY_NOT_AVAILABLE = "TRAY_NOT_AVAILABLE",
    CHECKIN_CANCELLED = "CHECKIN_CANCELLED",
    DATA_FOR_VERIFICATION_READY_TO_BE_READ = "DATA_FOR_VERIFICATION_READY_TO_BE_READ",
    BAG_SENT_AND_CONFIRM_NEXT_BAG = "BAG_SENT_AND_CONFIRM_NEXT_BAG",
    CLOSED = "CLOSED",
    INITIALIZING = "INITIALIZING",
    REBOOT = "REBOOT",
    WAITING_FOR_POSITION_READY = "WAITING_FOR_POSITION_READY",
    SHUTDOWN = "SHUTDOWN"
}